<template>
  <div class="hp-prom">
    <div class="container" v-if="bottomPromotion">
      <span class="title-h2">{{ bottomPromotion?.title }} </span>
      <div class="subtitle">{{ bottomPromotion?.subtitle }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
  const { currentPage } = useGlobalStore();
  const { bottomPromotion } = currentPage?.template?.homepage || {};
</script>
